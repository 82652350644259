import * as echarts from "echarts";
/// 条形图
export function drawColumnChart(eleId, xData, seriesData) {
  let option = {
    xAxis: {
      max: "dataMax",
    },
    yAxis: {
      type: "category",
      data: xData,
      inverse: true,
      axisLabel: {},
    },
    grid: { top: "10%", left: "26%", right: "10%", bottom: "10%" },
    series: [
      {
        type: "bar",
        data: seriesData,
        label: {
          show: true,
          position: "right",
          valueAnimation: true,
        },
        itemStyle: {
          normal: {
            color: "rgba(47, 124, 255, .5)",
          },
        },
      },
    ],
    legend: {
      show: true,
    },
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
/// 水波图
export function drawLineChart(eleId, xData, seriesData) {
  let option = {
    xAxis: {
      type: "category",
      data: xData,
      splitLine: {
        show: false,
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      axisLabel: {
        interval: 0,
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: "{b} : <br/>调用次数: {c}",
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    grid: { top: "10%", left: "10%", right: "10%", bottom: "20%" },
    series: [
      {
        data: seriesData,
        type: "line",
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          color: "rgba(47, 124, 255, 1)",
        },
        areaStyle: {
          color: "rgba(47, 124, 255, .3)",
        },
        itemStyle: {
          normal: {
            color: "rgba(47, 124, 255, .5)",
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
/// 饼图
export function drawPieChart(eleId, xData, seriesData) {
  let option = {
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      right: "right",
      top: "middle",
    },
    series: [
      {
        name: "资源调用每日次数接口分布图",
        type: "pie",
        radius: "50%",
        center: ["30%", "50%"],
        data: seriesData,
        label: {
          normal: {
            show: true,
            formatter: "{d}%",
          },
        },
        emphasis: {
          center: ["40%", "50%"],
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 双折线
export function drawDLineChart(eleId, xData, seriesData1, seriesData2) {
  let option = {
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        interval: 0,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: "{b0}<br/>产生积分: {c1}<br />核销积分: {c0}",
    },
    grid: { top: "10%", left: "14%", right: "10%", bottom: "20%" },
    series: [
      {
        data: seriesData1,
        type: "line",
        symbolSize: 0,
        lineStyle: {
          type: "dashed",
          color: "#9FCAFF",
        },
      },
      {
        data: seriesData2,
        type: "line",
        symbolSize: 0,
        lineStyle: {
          color: "#FE9615",
        },
      },
    ],
  };
  drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
